import React, { useState, useEffect } from "react";
import { Dropdown, Text, Switch } from "@nextui-org/react";
import "./Navbar.css";
import logo from "../../../assets/images/logo.png";
import bellIcon from "../../../assets/images/bell.svg";
import houseIcon from "../../../assets/images/house.png";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import switchOnImage from "../../../assets/images/usa1.png";
import switchOffImage from "../../../assets/images/colombia1.png";
import menu from "../../../assets/images/menu.png";
import { getUserLogged } from "../../../services/fetchData";
import ajustes from "../../../assets/images/Ajustes.png";
import { Link } from "react-router-dom";

function Navbar() {
  const [t, i18n] = useTranslation("global");
  const [user, setUser] = useState("Guest");
  const [redirectURL, setredirectURL] = useState(
    "https://firmadocdashboard.login.portal-id.com/"
  );
  const location = useLocation();
  const [isEnglish, setIsEnglish] = useState(true);
  const [dataUserLogged, setDataUserLogged] = useState(null);
  const [userId, setUserId] = useState("");
  const [query, setQuery] = useState("");

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const userParam = query.get("user") ?? "Admin";
    const redirectURLParam =
      query.get("redirect") ?? "https://firmadocdashboard.login.portal-id.com/";
    const usuIdParam = query.get("usu_id") ?? "";

    console.log("Query user:", userParam);
    console.log("Query usu_id:", usuIdParam);

    setUserId(usuIdParam); // Actualiza el estado de userId
    setredirectURL(redirectURLParam); // Actualiza la URL de redirección
    setUser(userParam); // Guarda el valor del usuario

    // Llamar a getUserData después de actualizar userId
    if (usuIdParam) {
      getUserLogged(usuIdParam)
        .then((response) => {
          setDataUserLogged(response);
          console.log("Response from getUserLogged:", response);
          console.log("Contenido firma:", response.contenido_firma);
        })
        .catch((error) => {
          console.error("Error fetching user data:", error);
        });
    }

    clickOutside();
  }, [location]);
  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const userParam = query.get("user") ?? "Admin";
    const redirectURLParam =
      query.get("redirect") ?? "https://firmadocdashboard.login.portal-id.com/";
    const usuIdParam = query.get("usu_id") ?? "";

    console.log("Query user:", userParam);
    console.log("Query usu_id:", usuIdParam);

    setUserId(usuIdParam); // Actualiza el estado de userId
    setredirectURL(redirectURLParam); // Actualiza la URL de redirección
    setUser(userParam);

    // Llamar a getUserData después de actualizar userId
    if (usuIdParam) {
      getUserLogged(usuIdParam)
        .then((response) => {
          setDataUserLogged(response);
          console.log("Response from getUserLogged:", response);
          console.log("Contenido firma:", response.contenido_firma);
        })
        .catch((error) => {
          console.error("Error fetching user data:", error);
        });
    }

    clickOutside();
  }, [location]);

  function getUserData() {
    console.log("Fetching user data for userId:", userId);

    getUserLogged(userId)
      .then((response) => {
        setDataUserLogged(response); // Actualizamos el estado con los datos obtenidos
        console.log("Response from getUserLogged:", response);
        console.log("Contenido firma:", response.contenido_firma);

        // Aquí puedes trabajar directamente con "response"
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  }

  const clickOutside = () => {
    const handleClickOutside = (event) => {
      const menu = document.getElementById("menu-listado");
      const menuContainer = document.getElementById("menu-container");

      if (!menuContainer.contains(event.target)) {
        console.log("afuera");
        menu.classList.remove("menuOpen");
      }
    };
    document.addEventListener("click", handleClickOutside);
  };

  const handleLanguageToggle = () => {
    setIsEnglish(!isEnglish);
    if (isEnglish) {
      // Cambiar al idioma "en"
      i18n.changeLanguage("en");
    } else {
      // Cambiar al idioma "es"
      i18n.changeLanguage("es");
    }
  };
  function handleMenu() {
    console.log("hola");
    const navbar = document.getElementById("menu-listado");
    navbar.classList.add("menuOpen");
  }
  const MenuList = () => {
    return (
      <Dropdown>
        <Dropdown.Button light>
          <p className="container-sign-nav">
            {dataUserLogged ? (
              dataUserLogged.contenido_firma.startsWith("data:image/") ? (
                <img
                  src={dataUserLogged.contenido_firma}
                  alt="Firma"
                  style={{ maxHeight: "50px", maxWidth: "50px" }}
                />
              ) : (
                <span
                  style={{
                    fontFamily: dataUserLogged.tipo_fuente || "default",
                  }}
                >
                  {dataUserLogged.contenido_firma}
                </span>
              )
            ) : (
              "Cargando..."
            )}
          </p>
          <Text
            size={16}
            css={{
              fontFamily: "Poppins-ExtraLight",
              paddingRight: "-3%",
              paddingLeft: "3%",
              paddingTop: "1%",
              borderLeft: "2px solid gray",
            }}
          >
            <img src={bellIcon} className="min-icon" alt="alt" />
            {user}
          </Text>
        </Dropdown.Button>
        <Dropdown.Menu aria-label="Static Actions">
          {
            <Dropdown.Item key="new">
              {" "}
              <Link
                to="https://firmadocdashboard.login.portal-id.com/perfil/"
                className="links"
              >
                {t("navbar.Perfil")}
              </Link>
            </Dropdown.Item>
          }
          {/* <Dropdown.Item key="new">
            {" "}
            <Link to={redirectURL + "perfil/"} className="links">
              {t("navbar.Perfil")}
            </Link>
          </Dropdown.Item> */}
          {
            <Dropdown.Item key="copy">
              <Link
                to="https://firmadocdashboard.login.portal-id.com/firma_propia/"
                className="links"
              >
                {t("navbar.SubirGrafo")}
              </Link>
            </Dropdown.Item>
          }
          {/*  <Dropdown.Item key="copy">
            <Link to={redirectURL + "firma_propia/"} className="links">
              {t("navbar.SubirGrafo")}
            </Link>
          </Dropdown.Item> */}
          
          <Dropdown.Item key="exit">{t("navbar.Salir")}</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  return (
    <div className="menu-container" id="menu-container">
      <div className="logo">
        <a href="#">
          <img src={logo} alt="alt" className="logo" />
        </a>
      </div>

      <div className="container-nav">
        <ul id="menu-listado" className="nav">
          <li>
            {/* <a href={process.env.REACT_APP_NAVBAR_URL_HOME}>{t("navbar.home")}</a> */}

            <a href={redirectURL + "dashboard/"}>
              <img src={houseIcon} alt="alt" className="container-nav__icon" />
              {t("navbar.home")}
            </a>
          </li>
          <li>
            {/* <a href={process.env.REACT_APP_NAVBAR_URL_DOCUMENT}>{t("navbar.documents")}</a> */}
            <a href={redirectURL + "administrar/"}>{t("navbar.documents")}</a>
          </li>
          <li>
            {/* <a href={process.env.REACT_APP_NAVBAR_URL_TEMPLATE}>{t("navbar.templates")}</a> */}
            <a href={redirectURL + "plantillas/"}>{t("navbar.templates")}</a>
          </li>
          <li>
            {/* <a href={process.env.REACT_APP_NAVBAR_URL_REPORT}>{t("navbar.reports")}</a> */}
            <a href={"#"}>{t("navbar.reports")}</a>
          </li>
        </ul>
      </div>

      <div className="user-container">
        <ul className="user">
          <li>
            <MenuList style={{ marginLeft: "1%" }} />
            <img
              src={isEnglish ? switchOffImage : switchOnImage}
              alt="Switch de idioma"
              onClick={handleLanguageToggle}
              style={{ cursor: "pointer", height: "24px" }}
            />
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Navbar;