import { Loading, styled } from "@nextui-org/react";
import { useFetch } from "../../hooks/useFetch";
import { URL } from "../../utils/constants";
import { useTranslation } from "react-i18next";

const StyledContainerImg = styled("div", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const StyledImg = styled("img", {
  width: "250px",
  height: "250px",
});

function ModalContentTransaction({ data }) {
  const { t, i18n } = useTranslation("global");
  console.log(data);
  console.log(data.TransactionId);
  console.log(URL);

  const { data: transactionData, loading } = useFetch(
    `${URL}transactions/${data.TransactionId}/`
  );

  if (loading) {
    return (
      <div>
        <Loading></Loading>
      </div>
    );
  }
  console.log(transactionData);

  if (transactionData.TransactionTypeName == "Enroll") {
    return (
      <div>
        <table style={{ margin: "0 auto" }}>
          <tr>
            <td>
              <th style={{ width: "25%" }}>
                {t("ModalContentTransaction.IdentificaciónFacial")}
              </th>
              <StyledContainerImg
                style={{
                  border: "1px solid #ccc",
                  padding: "10px",
                  margin: "5px",
                }}
              >
                {transactionData.ImagesTransaction.clientFace ? (
                  <StyledImg
                    src={
                      "data:image/png;base64," +
                      transactionData.ImagesTransaction.clientFace
                    }
                  />
                ) : (
                  <p>{t("ModalContentTransaction.Nohayimagen")}</p>
                )}
              </StyledContainerImg>
            </td>
          </tr>
          <tr>
            <td>
              <th style={{ width: "25%" }}>
                {t("ModalContentTransaction.CedulaFrontal")}
              </th>

              <StyledContainerImg
                style={{
                  border: "1px solid #ccc",
                  padding: "10px",
                  margin: "5px",
                }}
              >
                {transactionData.ImagesTransaction.frontCroppedDocument ? (
                  <StyledImg
                    src={
                      "data:image/png;base64," +
                      transactionData.ImagesTransaction.frontCroppedDocument
                    }
                  />
                ) : (
                  <p>{t("ModalContentTransaction.Nohayimagen")}</p>
                )}
              </StyledContainerImg>
            </td>
          </tr>
          <tr>
            <td>
              <th style={{ width: "25%" }}>
                {t("ModalContentTransaction.Cedulatrasera")}
              </th>
              <StyledContainerImg
                style={{
                  border: "1px solid #ccc",
                  padding: "10px",
                  margin: "5px",
                }}
              >
                {transactionData.ImagesTransaction.backCroppedDocument ? (
                  <StyledImg
                    src={
                      "data:image/png;base64," +
                      transactionData.ImagesTransaction.backCroppedDocument
                    }
                  />
                ) : (
                  <p>{t("ModalContentTransaction.Nohayimagen")}</p>
                )}
                ,
              </StyledContainerImg>
            </td>
          </tr>
        </table>
        <table>
          <tr>
            <td>
              <th style={{ width: "23%" }}>VideoID</th>
              <StyledContainerImg
                style={{
                  border: "1px solid #ccc",
                  padding: "10px",
                  margin: "5px",
                }}
              >
                {transactionData.ImagesTransaction.liveness ? (
                  <video controls style={{ width: "79%", margin: "0 auto" }}>
                    <source
                      src={
                        "data:video/mp4;base64," +
                        transactionData.ImagesTransaction.liveness
                      }
                      type="video/mp4"
                    />
                  </video>
                ) : (
                  <p>{t("ModalContentTransaction.Nohayimagen")}</p>
                )}
              </StyledContainerImg>
            </td>
          </tr>
        </table>
      </div>
    );
  } else {
    return (
      <div>
        <table style={{ margin: "0 auto" }}>
          <tr>
            <td>
              <th style={{ width: "25%" }}>
                {t("ModalContentTransaction.IdentificaciónFacial")}
              </th>
              <StyledContainerImg
                style={{
                  border: "1px solid #ccc",
                  padding: "10px",
                  margin: "5px",
                }}
              >
                {transactionData.ImagesTransaction.clientFace ? (
                  <StyledImg
                    src={
                      "data:image/png;base64," +
                      transactionData.ImagesTransaction.clientFace
                    }
                  />
                ) : (
                  <p>{t("ModalContentTransaction.Nohayimagen")}</p>
                )}
              </StyledContainerImg>
            </td>
          </tr>
        </table>
        <table>
          <tr>
            <td>
              <th style={{ width: "23%" }}>VideoID</th>
              <StyledContainerImg
                style={{
                  border: "1px solid #ccc",
                  padding: "10px",
                  margin: "5px",
                }}
              >
                {transactionData.ImagesTransaction.liveness ? (
                  <video controls style={{ width: "76%", margin: "0 auto" }}>
                    <source
                      src={
                        "data:video/mp4;base64," +
                        transactionData.ImagesTransaction.liveness
                      }
                      type="video/mp4"
                    />
                  </video>
                ) : (
                  <p>{t("ModalContentTransaction.Nohayimagen")}</p>
                )}
              </StyledContainerImg>
            </td>
          </tr>
        </table>
      </div>
    );
  }
}

export default ModalContentTransaction;
