import React from "react";
import DataTable from "react-data-table-component";

const Table = ({ columns, data, subHeaderComponent, expandableComponent, dense = true }) => {
  // Opciones de texto de paginación en español
  const paginationOptions = {
    rowsPerPageText: "Filas por página",
    rangeSeparatorText: "de",
    noRowsPerPage: false, // Muestra el selector de filas por página
    selectAllRowsItem: false, // Si deseas incluir una opción "Todos", cámbialo a true
    selectAllRowsItemText: "Todos",
  };

  return (
    <DataTable
      columns={columns}
      data={data}
      pagination
      paginationComponentOptions={paginationOptions}
      expandableRows={!!expandableComponent}
      expandableRowsComponent={expandableComponent}
      subHeader={!!subHeaderComponent}
      subHeaderWrap={true}
      subHeaderComponent={subHeaderComponent}
      dense={dense}
    />
  );
};

export default Table;