import { useState } from "react";
import GeneralCard from "../Cards/GeneralCard";
import { useFetch } from "../../hooks/useFetch";
import { Loading } from "@nextui-org/react";
import Table from "../Table/Table";
import { URL, ConcumptionColumnsFunction } from "../../utils/constants";
import GeneralModal from "../Modal/GeneralModal";
import ModalContentConsumption from "../ModalContent/ModalContentConsumption";
import { useTranslation } from "react-i18next";


function Consumption({ boughtFirms, usedFirms, totalDocuments }) {
  const { t , i18n } = useTranslation("global");
  const [showModal, setShowModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const { data, loading } = useFetch(`${URL}user/details`);

  const handleClickDocument = (row) => {
    setSelectedRow(row);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const columns = ConcumptionColumnsFunction(handleClickDocument, i18n.resolvedLanguage);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          flexWrap: "nowrap",
          margin: "0.5rem",
          gap: "1rem",

        }}
      >
        <GeneralCard title={t("Consumption.FirmasDisponible")} value={boughtFirms} />
        <GeneralCard title={t("Consumption.FirmasPendientes")} value={usedFirms} />
        <GeneralCard
          title={t("Consumption.DocumentosFirmados")}
          value={totalDocuments}
        />
      </div>

      <div style={{ margin: "0.5rem" }}>

        {loading ? (
          <Loading />
        ) : (
          data && <Table columns={columns} data={data} expandableRows={true} />
        )}

        <GeneralModal
          title={t("Consumption.Documentosdeusuario")}
          size={'900px'}
          component={
            <div style={{ overflowY: 'auto' }}>
              <ModalContentConsumption data={selectedRow} />
            </div>}
          visible={showModal}
          onClose={closeModal}
        />
       

      </div>
    </>
  );
}

export default Consumption;
