import React from "react";
import { Input, Spacer } from "@nextui-org/react";

function GeneralInput({ id, label, type, onChange, placeholder }) {
  const containerStyles = {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#f0f0f0", // Equivalente a $gray-light
    width: "100%",
    maxWidth: "30rem",
    justifyContent: "space-between",
    alignItems: "center",
    height: "2.5rem", // Equivalente a $button-size-height
    border: "1px solid #f0f0f0", // Equivalente a $gray-light
    boxSizing: "border-box",
  };

  const inputStyles = {
    width: "100%",
    border: "none",
    backgroundColor: "#f0f0f0",
    fontSize: "0.875rem", // Equivalente a $text-size-short
  };

  return (
    <section style={containerStyles} class="general-input">
      <Input
        clearable
        bordered={false} // Quita el borde predeterminado del componente
        id={id}
        placeholder={placeholder??'Buscar'} // Cambia labelPlaceholder por placeholder
        type={type}
        onChange={(e) => onChange(e.target.value)}
        css={inputStyles} // Aplica los estilos personalizados al input
      />
      <Spacer x={0.5} />
    </section>
  );
}

export default GeneralInput;
